import { fromJS } from 'immutable'
export default function userInterfaceReducer(
    state = fromJS({
        notification: null
    }),
    action) {
    switch (action.type) {
        case 'UI_SHOW_SNACK_BAR': {
            return state.set('notification', fromJS(action.payload))
        }
        case 'UI_HIDE_SNACK_BAR': {
            return state.set('notification', null)
        }

        //adding code to handle all errors from one location
        // case "MENTIONS_FETCH_WEB_ERROR":
        // case "ETCH_UNREAD_WEB_MENTIONS_ERROR":
        // case "MENTIONS_FETCH_SOURCES_ERROR":
        // case "MENTIONS_FETCH_NEW_SOURCES_ERROR":
        // case "MENTIONS_DELETE_PROJECT_SOURCES_ERROR":
        // case "MENTIONS_INSERT_KEYWORD_ERROR":
        // case "MENTIONS_DELETE_KEYWORD_ERROR":
        // case "MENTIONS_FETCH_KEYWORDS_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TRENDS_TRACKING_ERROR":
        // case "MENTIONS_FETCH__TRENDS_ERROR":
        // case "MENTIONS_EXPORT_YOUTUBE_TRENDS_ERROR":
        // case "MENTIONS_FETCH_GOOGLE_TRENDS_ERROR":
        // case "MENTIONS_FETCH_GOOGLE_TRENDS_NOTIFICATIONS_ERROR":
        // case "MENTIONS_FILTER_GOOGLE_TRENDS_ERROR":
        // case "MENTIONS_EXPORT_GOOGLE_TRENDS_ERROR":
        // case "MENTIONS_FETCH_BRAND_TRENDS_NOTIFICATIONS_ERROR":
        // case "MENTIONS_FETCH_BRAND_TRENDS_ERROR":
        // case "MENTIONS_FILTER_BRAND_TRENDS_ERROR":
        // case "MENTIONS_EXPORT_BRAND_TRENDS_ERROR":
        // case "MENTIONS_MARK_BRAND_TREND_MENTION_READ_ERROR":
        // case "MENTIONS_FETCH_TWITTER_TRENDS_ERROR":
        // case "MENTIONS_EXPORT_TWITTER_TRENDS_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        // case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":


        case "FACEBOOK_FETCH_USER_TOKEN_ERROR":
        case "FACEBOOK_FETCH_USER_INBOX_MESSAGES_ERROR":
        case "FACEBOOK_REPLY_USER_INBOX_MESSAGE_ERROR":
        case "FACEBOOK_OPEN_MESSAGE_ERROR":
        case "FACEBOOK_FETCH_MENTIONS_ERROR":
        case "FACEBOOK_FETCH_POST_COMMENTS_ERROR":
        case "FIREBASE_GENERATE_USERTOKEN_ERROR":
        case "MENTIONS_FETCH_WEB_ERROR":
        case "FETCH_UNREAD_WEB_MENTIONS_ERROR":
        case "MENTIONS_FETCH_SOURCES_ERROR":
        case "MENTIONS_FETCH_NEW_SOURCES_ERROR":
        case "MENTIONS_DELETE_PROJECT_SOURCES_ERROR":
        case "MENTIONS_INSERT_KEYWORD_ERROR":
        case "MENTIONS_DELETE_KEYWORD_ERROR":
        case "MENTIONS_FETCH_KEYWORDS_ERROR":
        case "MENTIONS_UPDATE_KEYWORD_TWITTER_TRACKING_ERROR":
        case "MENTIONS_UPDATE_KEYWORD_TRENDS_TRACKING_ERROR":
        case "MENTIONS_FETCH__TRENDS_ERROR":
        case "MENTIONS_EXPORT_YOUTUBE_TRENDS_ERROR":
        case "MENTIONS_FETCH_GOOGLE_TRENDS_ERROR":
        case "MENTIONS_FETCH_GOOGLE_TRENDS_NOTIFICATIONS_ERROR":
        case "MENTIONS_FILTER_GOOGLE_TRENDS_ERROR":
        case "MENTIONS_EXPORT_GOOGLE_TRENDS_ERROR":
        case "MENTIONS_FETCH_BRAND_TRENDS_NOTIFICATIONS_ERROR":
        case "MENTIONS_FETCH_BRAND_TRENDS_ERROR":
        case "MENTIONS_FILTER_BRAND_TRENDS_ERROR":
        case "MENTIONS_EXPORT_BRAND_TRENDS_ERROR":
        case "MENTIONS_MARK_BRAND_TREND_MENTION_READ_ERROR":
        case "MENTIONS_FETCH_TWITTER_INFLUENCERS_ERROR":
        case "MENTIONS_FETCH_TWITTER_TRENDS_ERROR":
        case "MENTIONS_EXPORT_TWITTER_TRENDS_ERROR":
        case "MENTIONS_FETCH_TWITTER_TRENDS_NOTIFICATIONS_ERROR":
        case "MENTIONS_FILTER_TWITTER_TRENDS_ERROR":
        case "MENTIONS_FETCH_TWITTER_MENTIONS_ERROR":
        case "TWITTER_BUBBLE_CHART_DATA_BEGIN_ERROR":
        case "MENTIONS_FETCH_USER_PROJECTS_ERROR":
        case "INSERT_NEW_PROJECT_ERROR":
        case "MENTIONS_FETCH_PROJECT_KEYWORDS_ERROR":
        case "DELETE_PROJECT_ERROR":
        case "MENTIONS_TOGGLE_SLIDE_PREFERENCE_ERROR":
        case "MENTIONS_FETCH_SELECTED_PROJECT_ERROR":
        case "MENTIONS_FETCH_SELECTED_PROJECT_SOURCES_ERROR":
        case "MENTIONS_INSERT_PROJECT_SOURCES_ERROR":
        case "MENTIONS_TRACK_GLOBAL_NEWS_ERROR":  
        case "INSERT_NEW_KEYWORD_ERROR":
        case "MENTIONS_FETCH_WEB_MENTIONS_GRAPH_ERROR":
        case "MENTIONS_FETCH_TWITTER_MENTIONS_GRAPH_ERROR":
        case "MENTIONS_FETCH_TWITTER_MENTIONS_GRAPH_ANALYTICS_ERROR":  
        case "MENTIONS_FETCH_INSTAGRAM_MENTIONS_ANALYTICS_ERROR":
        case "MENTIONS_FETCH_WEB_MENTIONS_ANALYTICS_ERROR" :     
        case "MENTIONS_FILTER_DATA_ERROR":
        case "MENTIONS_FILTER_WEB_PAGE_ERROR":
        case "MENTIONS_FETCH_WEB_MENTIONS_NOTIFICATIONS_BAR_ERROR":
        case "MENTIONS_MARK_WEB_MENTION_READ_ERROR":
        case "MENTIONS_FETCH_ALL_WEB_MENTIONS_ERROR":
        case "MMENTIONS_LOAD_WEB_MENTIONS_MANUALLY_ERROR":
        case "MENTIONS_LOAD_TWITTER_MENTIONS_MANUALLY_ERROR":
        case "MENTIONS_LOAD_INSTAGRAM_MENTIONS_MANUALLY_ERROR":
        case "MENTIONS_FILTER_ALL_WEB_MENTIONS_ERROR":
        case "MENTIONS_EXPORT_WEB_MENTIONS_EXCEL_ERROR":
        case "MENTIONS_FETCH_TWITTER_NOTIFICATIONS_ERROR":
        case "MENTIONS_MARK_TWEET_MENTION_READ_ERROR":
        case "MENTIONS_FETCH_TWITTER_MENTIONS_NOTIFICATIONS_BAR_ERROR":
        case "MENTIONS_FILTER_ALL_TWITTER_MENTIONS_ERROR":
        case "MENTIONS_EXPORT_ALL_TWITTER_MENTIONS_EXCEL_ERROR":
        case "MENTIONS_CLEAR_ALL_USER_TWITTER_NOTIFICATIONS_ERROR":
        case "MENTIONS_FETCH_USER_DIRECT_MESSAGES_ERROR":
        case "MENTIONS_REPLY_TWITTER_DIRECT_MESSAGE_ERROR":
        case "MENTIONS_FETCH_TWITTER_USER_MENTIONS_TIMELINE_ERROR":
        case "MENTIONS_FETCH_TWITTER_USER_TIMELINE_ERROR":
        case "MENTIONS_RETWEET_TWITTER_USER_TWEET_TIMELINE_ERROR":
        case "MENTIONS_FAVORITE_TWITTER_USER_TWEET_ERROR":
        case "MENTIONS_REPLY_TWITTER_USER_TWEET_ERROR":
        case "SETTINGS_INSERT_SMS_CONTACT_ERROR":
        case "SETTINGS_FETCH_USER_SMS_CONTACTS_ERROR":
        case "SETTINGS_FETCH_USER_PACKAGE_ERROR":
        case "SETTINGS_DELETE_USER_SMS_CONTACT_ERROR":
        case "TWITTER_FETCH_DEMO_USERS_ERROR":
        case "TWITTER_FETCH_TWEETS_ERROR":
        case "TWITTER_BUBBLE_CHART_DATA_BEGIN_ERROR":
        case "FACEBOOK_FETCH_USER_TOKEN_ERROR":
        case "MENTIONS_FETCH_TWITTER_REPORT_SEARCH_ERROR":       
            {
                console.log("An error occured")
                console.log("Error occured")
                let code = ""
                if (action.payload && action.payload.code)
                    code = action.payload.code
                const notification = {
                    open: true,
                    error: "ERROR " + code,
                    message: action.payload && action.payload.error ? action.payload.error.message : "An Error occured!",
                    color: "danger",
                    background: "white"
                }
                return state.set('notification', fromJS(notification))
            }

        case "INSERT_NEW_PROJECT_FINISHED": 
        case "MENTIONS_TRACK_PROJECT_SOURCES_BULK_FINISHED":
        case "DELETE_PROJECT_FINISHED":
        case "MENTIONS_INSERT_PROJECT_SOURCES_FINISHED":
        case "MENTIONS_TRACK_GLOBAL_NEWS_FINISHED":    
        case "MENTIONS_DELETE_PROJECT_SOURCES_FINISHED":
        case "MENTIONS_UPDATE_KEYWORD_TRENDS_TRACKING_FINISHED":
        case "MENTIONS_UPDATE_KEYWORD_INSTAGRAM_TRACKING_FINISHED":   
        case "MENTIONS_UPDATE_KEYWORD_WEB_TRACKING_FINISHED" :
        case "MENTIONS_UPDATE_KEYWORD_TIKTOK_TRACKING_FINISHED":
        case "MENTIONS_UPDATE_KEYWORD_YOUTUBE_TRACKING_FINISHED":        
        case "MENTIONS_LOAD_WEB_MENTIONS_MANUALLY_FINISHED":
        case "MENTIONS_LOAD_TWITTER_MENTIONS_MANUALLY_FINISHED":
        case "MENTIONS_LOAD_INSTAGRAM_MENTIONS_MANUALLY_FINISHED":
        case "FACEBOOK_FETCH_USER_TOKEN_FINISH":        
        case "MENTIONS_DELETE_KEYWORD_FINISHED":
        case "MENTIONS_FETCH_TWITTER_REPORT_SEARCH_ERROR":{     
            const notification = {
                open: true,
                message: action.payload && action.payload.message ? action.payload.message : "Success!",
                color: "success",
                background: "white"
            }
            return state.set('notification', fromJS(notification))
        }

        //end of code to handle all errors from one place

    }
    return state;
}
