import { fromJS } from 'immutable'
export default function mentionsReducer(
    state = fromJS({
        web: [],
        twitter: [],
        sources: { sources: [], sourcesready: false },
        newSources: { newSources: [], newsourcesready: false },
        keywords: { keywords: [], keywordsready: false },
        keyword_mentions_scraper: { status: false },
        youtube_trends: { youtubetrendsdata: [], youtubetrendscount: 0, youtubetrendsready: false },
        youtube_trends_excel: { youtubetrendsdata: [], pdfData: [], youtubetrendsready: false },
        youtube_mentions: { youtubeMentions: [], pageCount: 0, youtube_mentions_ready: false },
        youtube_mentions_excel: { youtubementionsdata: [], youtubementionsready: false },
        google_trends: { googletrendsdata: [], googletrendscount: 0, googletrendsready: false },
        google_trends_excel: { googletrendsdata: [], pdfData: [], googletrendsready: false },
        google_trends_notifications_bar: { googletrendsdata: [], googletrendscount: 0 },
        brand_trends: { brandtrendsdata: [], brandtrendscount: 0, brandtrendsready: false },
        brand_trends_excel: { brandtrendsdata: [], pdfData: [], brandtrendsready: false },
        brand_trends_notifications_bar: { brandtrendsdata: [], brandtrendscount: 0 },
        media_web_search_mentions: { mediaSearchMentions: [], pageCount: 0, media_search_mentions_ready: false },
        media_web_search_trend_chart: { trendchartdata: [], trendchartdatalabels: [], sentimentchartdata: [], media_web_search_trend_ready: false },
        media_web_search_metrics: { totalNumberOfMentions: 0, sentimentchartdatalabels: [], sentimentchartdata: [], entities: [], media_web_search_metrics_ready: false },
        ai_generated_insights: { aiChatInsights:"", aiChatInsightsReady: false },
        twitter_influencers: [],
        twitter_trends: { twittertrendsdata: [], twittertrendscount: 0, twittertrendsready: false },
        twitter_trends_excel: { twittertrendsdata: [], pdfData: [], twittertrendsready: false },
        twitter_trends_notifications: { twittertrendsdata: [], twittertrendscount: 0 },
        twitter_trend_meta: { twittertrendmetadata: [], twittertrendmetaready: false },
        twitter_report_mentions: { tweets: [], twitter_report_ready: false,insertedKeywordId:'' },
        twitter_report_user_keywords: { twitterReportKeywords: [], pageCount: 0, twitter_report_keywords_ready: false },
        twitter_keyword_mentions_scraper: { status: false },
        unreadwebmentions: [],
        userprojects: { userprojects: [], pageCount: 0, userprojectsready: false },
        usersocialmediacount: { socialmediamentionscountsready: false },
        web_mentions_graph: { graphlabels: [], negative_mentions: [], positive_neutral_mentions: [], yAxisMax: 500, web_mentions_graph_ready: false },
        manual_web_scraper: { status: false },
        web_notifications: { webMentions: [], pageCount: 0, webmentionsready: false },
        web_mentions_notifications_bar: { webMentions: [], pageCount: 0 },
        web_mentions_excel: { webmentionsdata: [], pdfData: [], webmentionsready: false },
        twitter_report_notifications: { twitterMentions: [], pageCount: 0, twitter_notifications_ready: false },
        twitter_notifications: { twitterMentions: [], pageCount: 0, twitter_notifications_ready: false },
        twitter_mentions_manual_scraper: { status: false },
        twitter_mentions_graph: { graphlabels: [], graphData: [], yAxisMax: 500, twitter_mentions_graph_ready: false },
        twitter_mentions_excel: { twittermentionsdata: [], pdfData: [], twittermentionsready: false },
        twitter_mentions_notifications_bar: { twitterMentions: [], pageCount: 0 },
        number_of_mentions: [],
        twitter_direct_messages: { messages: {}, userDetails: [] },
        twitter_user_mentions_timeline: [],
        twitter_user_timeline: [],
        tiktok_mentions: { tiktokMentions: [], pageCount: 0, tiktok_mentions_ready: false },
        twitter_filter_parameters:{pageNumber:'', project:'', keywords:'', userTextInput:'', startDate:null, endDate:null},
        tiktok_mentions_excel: { tiktokmentionsdata: [], tiktokmentionsready: false },
        tiktok_mentions_analytics_dashboard: { allMentionsData: 0, graphlabels: [], graphData: [], yAxisMax: 500, genderData: [], hashtagData: [], popularTopics: [], brandMentionsEmojis: [], emojiAnalyticsData: [], weekTrends: {}, timeTrends: {}, userMentionedLocationsKenya: [], tiktok_mentions_graph_ready: false },
        tiktok_filter_parameters:{},
        fb_tagged_mentions: { fbTaggedMentions: [], pageCount: 0, fb_tagged_mentions_ready: false },
        instagram_mentions: { instagramMentions: [], pageCount: 0, instagram_mentions_ready: false },
        instagram_mentions_excel: { instagrammentionsdata: [], instagrammentionsready: false },
        instagram_mentions_manual_scraper: { status: false },
        instagram_filter_parameters:{},
        facebook_page_mentions: { dataset: [] },
        twitter_mentions_analytics_dashboard: { allMentionsData: 0, graphlabels: [], negative_mentions: [], positive_neutral_mentions: [], allMentionsDataGraph: [], yAxisMax: 500, genderData: [], hashtagData: [], popularTopics: [], brandMentionsEmojis: [], emojiAnalyticsData: [], sentimentData: [], popularUsers: [], weekTrends: {}, timeTrends: {}, snapshotImages: [], userLocationsKenya: [], twitterUserMetrics: [], userMentionedLocationsKenya: [], taggedUsers: [], twitter_mentions_graph_ready: false },
        twitter_report_mentions_analytics_dashboard: { allMentionsData: 0, graphlabels: [], negative_mentions: [], positive_neutral_mentions: [], allMentionsDataGraph: [], yAxisMax: 500, genderData: [], hashtagData: [], popularTopics: [], brandMentionsEmojis: [], emojiAnalyticsData: [], sentimentData: [], popularUsers: [], weekTrends: {}, timeTrends: {}, snapshotImages: [], userLocationsKenya: [], twitterUserMetrics: [], userMentionedLocationsKenya: [], taggedUsers: [], twitter_mentions_graph_ready: false },
        instagram_mentions_analytics_dashboard: { allMentionsData: 0, graphlabels: [], graphData: [], yAxisMax: 500, genderData: [], hashtagData: [], popularTopics: [], brandMentionsEmojis: [], emojiAnalyticsData: [], weekTrends: {}, timeTrends: {}, userMentionedLocationsKenya: [], instagram_mentions_graph_ready: false },
        web_mentions_analytics_dashboard: { allMentionsData: 0, graphlabels: [], negative_mentions: [], positive_neutral_mentions: [], allMentionsDataGraph: [], yAxisMax: 500, hashtagData: [], popularTopics: [], sentimentData: [], descriptiveWords: [], popularMediaCategory: [], popularMediaSites: [], weekTrends: {}, timeTrends: {}, snapshotImages: [], instagram_mentions_graph_ready: false }



    }),
    action) {
    switch (action.type) {
        case 'MENTIONS_FETCH_WEB_FINISHED': {
            return state.set('web', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_SOURCES_FINISHED": {
            return state.set('sources', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_KEYWORDS_FINISHED": {
            console.log(" we are in the store fetching keywords")
            return state.set('keywords', fromJS(action.payload))
        }
        case "MENTIONS_INSERT_KEYWORD_FINISHED": {
            return state.set('keywords', fromJS(action.payload))
            // return state.set('keywords',state.get('keywords').insert(0,fromJS(action.payload) ))
        }

        case "TOGGLE_KEYWORD_SCRAPER_LOADER_ON": {
            return state.set('keyword_mentions_scraper', fromJS(action.payload))
        }

        case "TOGGLE_KEYWORD_SCRAPER_LOADER_OFF": {
            return state.set('keyword_mentions_scraper', fromJS(action.payload))
        }

        case "MENTIONS_DELETE_KEYWORD_FINISHED": {
            return state.set('keywords', fromJS(action.payload))
            // return state.set('keywords',state.get('keywords').insert(0,fromJS(action.payload) ))
        }

        case "MENTIONS_ANALYZE_MENTIONS_AI_FINISHED":{
            return state.set('ai_generated_insights', fromJS(action.payload))
            
        }

        case "MENTIONS_ANALYZE_MENTIONS_AI_STARTED":{
            return state.set('ai_generated_insights', fromJS(action.payload))
            
        }

        //updating youtube trends store to show loader as component loads

        case "MENTIONS_YOUTUBE_MENTIONS_FINISHED": {
            return state.set('youtube_mentions', fromJS(action.payload))
        }

        case "MENTIONS_YOUTUBE_MENTIONS_PAGE_STARTED": {
            return state.mergeIn(['youtube_mentions'], fromJS({ youtube_mentions_ready: action.payload.youtube_mentions_ready }));
        }
        case "MENTIONS_YOUTUBE_MENTIONS_PAGE_FINISHED": {
            return state.mergeIn(['youtube_mentions'], fromJS({ youtubeMentions: action.payload.youtubeMentions, youtube_mentions_ready: action.payload.youtube_mentions_ready }));
        }

        case "MENTIONS_EXPORT_ALL_YOUTUBE_MENTIONS_EXCEL_FINISHED": {
            return state.set('youtube_mentions_excel', fromJS(action.payload))
        }


        case "MENTIONS_FETCH_YOUTUBE_TRENDS_BEGIN": {
            return state.set('youtube_trends', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_YOUTUBE_TRENDS_FINISHED": {
            return state.set('youtube_trends', fromJS(action.payload))
        }
        case "MENTIONS_EXPORT_YOUTUBE_TRENDS_FINISHED": {
            return state.set('youtube_trends_excel', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_GOOGLE_TRENDS_BEGIN": {
            return state.set('google_trends', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_GOOGLE_TRENDS_FINISHED": {
            return state.set('google_trends', fromJS(action.payload))
        }


        case "MENTIONS_FILTER_GOOGLE_TRENDS_BEGIN": {
            return state.set('google_trends', fromJS(action.payload))
        }
        case "MENTIONS_FILTER_GOOGLE_TRENDS_FINISHED": {
            return state.set('google_trends', fromJS(action.payload))
        }

        case "MENTIONS_EXPORT_GOOGLE_TRENDS_FINISHED": {
            return state.set('google_trends_excel', fromJS(action.payload))
        }


        case "MENTIONS_FETCH_GOOGLE_TRENDS_NOTIFICATIONS_FINISHED": {
            return state.set('google_trends_notifications_bar', fromJS(action.payload))
        }



        case "MENTIONS_FETCH_BRAND_TRENDS_FINISHED": {
            return state.set('brand_trends', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_BRAND_TRENDS_NOTIFICATIONS_FINISHED": {
            return state.set('brand_trends_notifications_bar', fromJS(action.payload))
        }

        case "MENTIONS_FILTER_BRAND_TRENDS_FINISHED": {
            return state.set('brand_trends', fromJS(action.payload))
        }
        case "MENTIONS_EXPORT_BRAND_TRENDS_FINISHED": {
            return state.set('brand_trends_excel', fromJS(action.payload))
        }
        //action for media web seach UI
        case "MENTIONS_FETCH_MEDIA_SEARCH_MENTIONS_FINISHED": {
            return state.set('media_web_search_mentions', fromJS(action.payload))
        }
        case "MENTIONS_WEB_MEDIA_SEARCH_TREND_CHART_FINISHED": {
            return state.set('media_web_search_trend_chart', fromJS(action.payload))
        }
        case "MENTIONS_WEB_MEDIA_SEARCH_METRICS_FINISHED": {
            return state.set('media_web_search_metrics', fromJS(action.payload))
        }
        //end of media web search reducers
        //action for twittr mentions
        case "MENTIONS_LOAD_TWITTER_MENTIONS_MANUALLY_STARTED": {
            return state.set('twitter_mentions_manual_scraper', fromJS(action.payload))
        }

        case "MENTIONS_LOAD_TWITTER_MENTIONS_MANUALLY_FINISHED": {
            return state.set('twitter_mentions_manual_scraper', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_TWITTER_INFLUENCERS_FINISHED": {
            return state.set('twitter_influencers', fromJS(action.payload))
        }


        case "MENTIONS_FETCH_TWITTER_TRENDS_BEGIN": {
            return state.set('twitter_trends', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_TWITTER_TRENDS_FINISHED": {
            return state.set('twitter_trends', fromJS(action.payload))
        }
        //fetching twitter trend META DATA
        case "MENTIONS_FETCH_TWITTER_TREND_META_BEGIN": {
            return state.set('twitter_trend_meta', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_TWITTER_TREND_META_FINISHED": {
            return state.set('twitter_trend_meta', fromJS(action.payload))
        }
        //updating twitter trends store to create loader on twitter trends UI as data loads


        case "MENTIONS_FILTER_TWITTER_TRENDS_BEGIN": {
            return state.set('twitter_trends', fromJS(action.payload))
        }

        case "MENTIONS_FILTER_TWITTER_TRENDS_FINISHED": {
            return state.set('twitter_trends', fromJS(action.payload))
        }

        case "MENTIONS_EXPORT_TWITTER_TRENDS_FINISHED": {
            return state.set('twitter_trends_excel', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_TWITTER_TRENDS_NOTIFICATIONS_FINISHED": {
            return state.set('twitter_trends_notifications', fromJS(action.payload))
        }

        //start twittr search report reducer logic


        case "MENTIONS_FETCH_TWITTER_REPORT_SEARCH_FINISHED": {
            return state.set('twitter_report_mentions', fromJS(action.payload))
        }

        case "TOGGLE_TWITTER_KEYWORD_REPORT_SCRAPER_LOADER_ON": {
            return state.set('twitter_keyword_report_mentions_scraper', fromJS(action.payload))
        }

        case "TOGGLE_TWITTER_KEYWORD_REPORT_SCRAPER_LOADER_OFF": {
            return state.set('twitter_keyword_report_mentions_scraper', fromJS(action.payload))
        }




        //start facebook reducer logic
        case "MENTIONS_FETCH_FB_TAGGED_MENTIONS_FINISHED": {
            return state.set('fb_tagged_mentions', fromJS(action.payload))
        }

        //end of facebook reducer logic



        //start tiktok reducer logic

        //end tiktok reducer logic
        case "MENTIONS_TIKTOK_MENTIONS_FINISHED": {
            return state.set('tiktok_mentions', fromJS(action.payload))
        }

        case "MENTIONS_TIKTOK_MENTIONS_PAGE_STARTED": {
            return state.mergeIn(['tiktok_mentions'], fromJS({ tiktok_mentions_ready: action.payload.tiktok_mentions_ready }));
        }

        case "MENTIONS_TIKTOK_MENTIONS_PAGE_FINISHED": {
            return state.mergeIn(['tiktok_mentions'], fromJS({ tiktokMentions: action.payload.tiktokMentions, tiktok_mentions_ready: action.payload.tiktok_mentions_ready }));
        }




        case "MENTIONS_EXPORT_ALL_TIKTOK_MENTIONS_EXCEL_FINISHED": {
            return state.set('tiktok_mentions_excel', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_TIKTOK_MENTIONS_ANALYTICS_FINISHED": {
            return state.set('tiktok_mentions_analytics_dashboard', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_TIKTOK_MENTIONS_ANALYTICS_BEGIN": {
            return state.set('tiktok_mentions_analytics_dashboard', fromJS(action.payload))
        }

        case "MENTIONS_SET_FILTERS_FOR_TIKTOK_ANALYTICS_DASHBOARD": {
            console.log(" In store setting filters for analytics dashboard", fromJS(action.payload))
            return state.set('tiktok_filter_parameters', fromJS(action.payload))

        }
        //instagram reducer logic

        case "MENTIONS_FETCH_IG_MENTIONS_FINISHED": {
            return state.set('instagram_mentions', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_IG_MENTIONS_PAGE_STARTED": {
            return state.mergeIn(['instagram_mentions'], fromJS({ instagram_mentions_ready: action.payload.instagram_mentions_ready }));
        }
        case "MENTIONS_FETCH_IG_MENTIONS_PAGE_FINISHED": {
            return state.mergeIn(['instagram_mentions'], fromJS({ instagramMentions: action.payload.instagramMentions, instagram_mentions_ready: action.payload.instagram_mentions_ready }));
        }

        case "MENTIONS_EXPORT_ALL_INSTAGRAM_MENTIONS_EXCEL_FINISHED": {
            return state.set('instagram_mentions_excel', fromJS(action.payload))
        }


        case "MENTIONS_LOAD_INSTAGRAM_MENTIONS_MANUALLY_FINISHED": {
            return state.set('instagram_mentions_manual_scraper', fromJS(action.payload))
        }
        case "MENTIONS_LOAD_INSTAGRAM_MENTIONS_MANUALLY_STARTED": {
            return state.set('instagram_mentions_manual_scraper', fromJS(action.payload))
        }
     
        case "MENTIONS_SET_FILTERS_FOR_INSTAGRAM_ANALYTICS_DASHBOARD": {
            console.log(" In store setting filters for analytics dashboard", fromJS(action.payload))
            return state.set('instagram_filter_parameters', fromJS(action.payload))

        }
        //end of Instagram logic

        //analytics dashboard reducer logic

        //facebook page mentions analytics reducer logic

        case "MENTIONS_FETCH_FBPAGE_MENTIONS_STARTED": {
            return state.set('facebook_page_mentions', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_FBPAGE_MENTIONS_FINISHED": {
            return state.set('facebook_page_mentions', fromJS(action.payload))
        }


        //end of page mentions analytics reducer logic
        case "MENTIONS_FETCH_TWITTER_MENTIONS_GRAPH_ANALYTICS_FINISHED": {
            // console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.set('twitter_mentions_analytics_dashboard', fromJS(action.payload))

        }

        case "MENTIONS_FETCH_TWITTER_REPORT_MENTIONS_GRAPH_ANALYTICS_FINISHED": {
            // console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.set('twitter_report_mentions_analytics_dashboard', fromJS(action.payload))

        }
        case "MENTIONS_FETCH_TWITTER_MENTIONS_GRAPH_ANALYTICS_BEGIN": {
            // console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.set('twitter_mentions_analytics_dashboard', fromJS(action.payload))

        }

        case "MENTIONS_FETCH_INSTAGRAM_MENTIONS_ANALYTICS_FINISHED": {
            return state.set('instagram_mentions_analytics_dashboard', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_INSTAGRAM_MENTIONS_ANALYTICS_BEGIN": {
            return state.set('instagram_mentions_analytics_dashboard', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_WEB_MENTIONS_ANALYTICS_FINISHED": {
            return state.set('web_mentions_analytics_dashboard', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_WEB_MENTIONS_ANALYTICS_BEGIN": {
            return state.set('web_mentions_analytics_dashboard', fromJS(action.payload))
        }




        //end of analytics dashboard logic


        case "FETCH_UNREAD_WEB_MENTIONS_FINISHED": {
            return state.set('unreadwebmentions', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_USER_PROJECTS_FINISHED": {
            return state.set('userprojects', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_USER_SOCIAL_MEDIA_MENTIONS_COUNT_FINISHED": {
            return state.set('usersocialmediacount', fromJS(action.payload))
        }
        case "INSERT_NEW_PROJECT_FINISHED": {
            // return state.set('userprojects', state.get('userprojects').insert(0, fromJS(action.payload)))
            return state.set('userprojects', (fromJS(action.payload)))
        }
        case "MENTIONS_FETCH_SELECTED_PROJECT_FINISHED": {
            return state.set('keywords', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_SELECTED_PROJECT_SOURCES_FINISHED": {
            return state.set('sources', fromJS(action.payload))
        }
        case "MENTIONS_DELETE_PROJECT_SOURCES_FINISHED": {
            return state.set('sources', fromJS(action.payload))
        }

        case "INSERT_NEW_KEYWORD_BEGIN": {
            return state.set('keywords', fromJS(action.payload))
        }
        case "INSERT_NEW_KEYWORD_FINISHED": {
            console.log(" we are in the store fetching keywords")
            return state.set('keywords', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_NEW_SOURCES_FINISHED": {
            console.log(" we are in the store fetch new sources")
            return state.set('newSources', fromJS(action.payload))
        }
        case "MENTIONS_INSERT_PROJECT_SOURCES_FINISHED": {
            console.log(" we are in the store fetch new sources")
            return state.set('newSources', fromJS(action.payload))
        }
        case "MENTIONS_TRACK_GLOBAL_NEWS_FINISHED": {
            console.log(" we are in the store fetch new sources")
            return state.set('newSources', fromJS(action.payload))
        }

        case "MENTIONS_TRACK_PROJECT_SOURCES_BULK_FINISHED": {
            console.log(" we are in the store fetch new sources")
            return state.set('newSources', fromJS(action.payload))
        }


        case "MENTIONS_TOGGLE_SLIDE_PREFERENCE_FINISHED": {
            console.log(" In store updating project information")
            return state.set('userprojects', fromJS(action.payload))

        }
        case "MENTIONS_FETCH_PROJECT_KEYWORDS_FINISHED": {
            console.log(" In store updating keyword data information")
            return state.set('keywords', fromJS(action.payload))

        }

        case "MENTIONS_FETCH_WEB_MENTIONS_GRAPH_FINISHED": {
            console.log(" In store updating mentions for past year")
            return state.set('web_mentions_graph', fromJS(action.payload))

        }

        case "MENTIONS_FILTER_DATA_FINISHED": {
            console.log(" In store updating mentions for past year", fromJS(action.payload))
            return state.set('web_mentions_graph', fromJS(action.payload))

        }


        //updating store to show load in the component
        case "MENTIONS_FETCH_ALL_WEB_MENTIONS_STARTED": {
            console.log(" In store updating webmentions state", fromJS(action.payload))
            return state.set('web_notifications', fromJS(action.payload))

        }
        case "MENTIONS_FETCH_ALL_WEB_MENTIONS_FINISHED": {
            console.log(" In store updating webmentions state", fromJS(action.payload))
            return state.set('web_notifications', fromJS(action.payload))

        }

        case "MENTIONS_FETCH_ALL_WEB_MENTIONS_PAGE_FINISHED": {
            console.log(" In store updating webmentions state", fromJS(action.payload))
            return state.mergeIn(['web_notifications'], fromJS({ webMentions: action.payload.webMentions }));
        }

        case "MENTIONS_LOAD_WEB_MENTIONS_MANUALLY_STARTED": {
            return state.set('manual_web_scraper', fromJS(action.payload))

        }

        case "MENTIONS_LOAD_WEB_MENTIONS_MANUALLY_FINISHED": {
            return state.set('manual_web_scraper', fromJS(action.payload))

        }


        //updating store to create loader as user clicks on filtered pages {Web mentions}
        case "MENTIONS_FILTER_ALL_WEB_MENTIONS_STARTED": {
            console.log(" In store updating webmentions state", fromJS(action.payload))
            return state.set('web_notifications', fromJS(action.payload))

        }
        case "MENTIONS_FILTER_ALL_WEB_MENTIONS_FINISHED": {
            console.log(" In store updating webmentions state", fromJS(action.payload))
            return state.set('web_notifications', fromJS(action.payload))

        }

        case "MENTIONS_EXPORT_WEB_MENTIONS_EXCEL_FINISHED": {
            console.log(" In store updating webmentions excel state", fromJS(action.payload))
            return state.set('web_mentions_excel', fromJS(action.payload))

        }

        case "MENTIONS_FETCH_TWITTER_REPORT_USER_KEYWORDS_FINISHED": {
            console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.set('twitter_report_user_keywords', fromJS(action.payload));

        }

        case "MENTIONS_FETCH_TWITTER_REPORT_NOTIFICATIONS_STARTED": {
            console.log(" In store...fetching twitter report notifications", fromJS(action.payload))
            return state.set('twitter_report_notifications', fromJS(action.payload))

        }

        case "MENTIONS_FETCH_TWITTER_REPORT_NOTIFICATIONS_PAGE_FINISHED": {
            console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.mergeIn(['twitter_report_notifications'], fromJS({ twitterMentions: action.payload.twitterMentions }));

        }

        case "MENTIONS_FETCH_TWITTER_REPORT_NOTIFICATIONS_FINISHED": {
            console.log(" In store...finished twitter report notifications", fromJS(action.payload))
            return state.set('twitter_report_notifications', fromJS(action.payload))

        }

        case "MENTIONS_FETCH_TWITTER_NOTIFICATIONS_STARTED": {
            console.log(" In store...fetching twitter notifications", fromJS(action.payload))
            return state.set('twitter_notifications', fromJS(action.payload))

        }

        case "MENTIONS_FETCH_TWITTER_NOTIFICATIONS_FINISHED": {
            console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.set('twitter_notifications', fromJS(action.payload))

        }
        case "MENTIONS_FETCH_TWITTER_NOTIFICATIONS_PAGE_FINISHED": {
            console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.mergeIn(['twitter_notifications'], fromJS({ twitterMentions: action.payload.twitterMentions }));

        }


        //to create a loader on every page click on filter twitter mentions
        case "MENTIONS_FILTER_ALL_TWITTER_MENTIONS_STARTED": {
            console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.set('twitter_notifications', fromJS(action.payload))

        }


        case "MENTIONS_FETCH_TWITTER_MENTIONS_GRAPH_FINISHED": {
            console.log(" In store updating twitter mentions", fromJS(action.payload))
            return state.set('twitter_mentions_graph', fromJS(action.payload))

        }



        case "MENTIONS_FILTER_ALL_TWITTER_MENTIONS_FINISHED": {
            console.log(" In store updating filtered twitter mentions", fromJS(action.payload))
            return state.set('twitter_notifications', fromJS(action.payload))

        }

        case "MENTIONS_EXPORT_ALL_TWITTER_MENTIONS_EXCEL_FINISHED": {
            console.log(" In store updating twitter mentions for export to excel", fromJS(action.payload))
            return state.set('twitter_mentions_excel', fromJS(action.payload))

        }


        case "MENTIONS_FETCH_TWITTER_MENTIONS_NOTIFICATIONS_BAR_FINISHED": {
            console.log(" In store updating twitter mentions notifications bar", fromJS(action.payload))
            return state.set('twitter_mentions_notifications_bar', fromJS(action.payload))

        }

        case "MENTIONS_SET_FILTERS_FOR_TWITTER_ANALYTICS_DASHBOARD": {
            console.log(" In store setting filters for analytics dashboard", fromJS(action.payload))
            return state.set('twitter_filter_parameters', fromJS(action.payload))

        }
        
        


        case "MENTIONS_FIND_NUMBER_PAGES_FINISHED": {
            console.log('MENTIONS_FIND_NUMBER_PAGES_FINISHED:', fromJS(action.payload))
            return state.set('number_of_mentions', fromJS(action.payload))
        }

        case "MENTIONS_FILTERED_PAGES_NUMBER_WEB_FINISHED": {
            console.log('MENTIONS_FILTERED_PAGES_NUMBER_WEB_FINISHED:', fromJS(action.payload))
            return state.set('number_of_mentions', fromJS(action.payload))
        }

        case "MENTIONS_FILTER_WEB_PAGE_FINISHED": {
            console.log('MENTIONS_FILTER_WEB_PAGE_FINISHED:', fromJS(action.payload))
            return state.set('web_notifications', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_WEB_MENTIONS_NOTIFICATIONS_BAR_FINISHED": {
            console.log('MENTIONS_NOTIFICATIONS_BAR FINISHED', fromJS(action.payload))
            return state.set('web_mentions_notifications_bar', fromJS(action.payload))
        }





        case "MENTIONS_FETCH_USER_DIRECT_MESSAGES_FINISH": {
            return state.set('twitter_direct_messages', fromJS(action.payload))
        }

        case "MENTIONS_FETCH_TWITTER_USER_MENTIONS_TIMELINE_FINISH": {
            return state.set('twitter_user_mentions_timeline', fromJS(action.payload))
        }
        case "MENTIONS_FETCH_TWITTER_USER_TIMELINE_FINISH": {
            return state.set('twitter_user_timeline', fromJS(action.payload))
        }












    }
    return state;
}
